import React from 'react';
import { dictionary } from '../../dictionary';
import MinorProjects from './MinorProjects';

const INTReportTable = (props) => {
  const { properties, styles } = props;
  const stagesDev = properties.eldData[0].stages_development.concat({ name: 'Other' });

  const hoursSum = properties.logsToDisplay.reduce((total, obj) => {
    return total + obj.hours;
  }, 0);

  const majorProjSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.module === 'Drobne_projekty') {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const learningConceptSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Learning Concept')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const storyboardSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Storyboard')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const visualVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Visual Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const clickableVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Clickable Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const adaptChangesSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Adapt Changes')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const finalVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Final Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const qCSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('QC')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const signOff = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Sign_off')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const other = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage === '') {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const sumValues = properties.logsToDisplay.reduce((acc, log) => {
    if (!acc[log.module]) {
      acc[log.module] = 0;
    }
    acc[log.module] += log.hours;
    return acc;
  }, {});
  
  const sumValuesArray = Object.values(sumValues);

  const tableObjDev = {
    learning_concept: learningConceptSum,
    storyBoard: storyboardSum,
    visual_version: visualVersionSum,
    clickable_version: clickableVersionSum,
    adapt_changes: adaptChangesSum,
    final_version: finalVersionSum,
    qc: qCSum,
    sign_off: signOff,
    other: other,
  };

  return (
    <>
      <thead>
        <tr>
          <th></th>
          {properties.logsToDisplay.map((log, index) => {
            return (
              <th key={index} className="header" style={styles.header}>
                {log.module}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{dictionary.export_table_th_dev}</td>
        </tr>
        {stagesDev.map((stage, index) => (
          <tr key={index} style={index % 2 === 0 ? styles.green : styles.blue}>
            <th className="thdefault" style={styles.thdefault}>
              {stage.name}
            </th>
            {properties.logsToDisplay.map((log, index) => {
              if (log.stage === stage.name || (stage.name === 'Other' && log.stage === '')) {
                return (
                  <td key={index} className="tddefault" style={styles.tddefault}>
                    {log.hours.toString().replace('.', ',')}
                  </td>
                );
              }
              return (
                <td key={index} className="tddefault" style={styles.tddefault}>
                  {''}
                </td>
              );
            })}
          </tr>
        ))}
        <tr className="orange" style={styles.orange}>
          <td className="orange" style={styles.orange}>
            Development - {new Date().getFullYear()} fiscal only
          </td>
          {sumValuesArray.map((sum, index) => (
            <td key={index} className="orange" style={styles.orange}>
              {sum.toString().replace('.', ',')}
            </td>
          ))}
        </tr>
        <tr>
          <th>{dictionary.export_table_th_dev}</th>
        </tr>
        {Object.keys(tableObjDev).map((key, index) => (
          <tr key={index}>
            <th className="gray" style={styles.gray}>
              {key}
            </th>
            <td key={key} className="tddefault" style={styles.tddefault}>
              {tableObjDev[key].toString().replace('.', ',')}
            </td>
          </tr>
        ))}
        <tr>
          <th className="gray" style={styles.gray}>
            {dictionary.export_table_summary}
          </th>
          <td className="orange" style={styles.orange}>
            {hoursSum.toString().replace('.', ',')}
          </td>
          <td>{<MinorProjects properties={properties} />}</td>
        </tr>
      </tbody>
    </>
  );
};

export default INTReportTable;
