import React, { useEffect, useState } from 'react';
import WorkPlanCompressed from './WorkPlanCompressed';
import DeskAvailability from './DeskAvailability';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import _, { entries } from 'lodash';
import { ProgressSpinner } from 'primereact/progressspinner';
import { dictionary } from '../../../dictionary';

const UsersWorkPlan = (props) => {
  const [expandAllEmployees, setExpandAllEmployees] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allLoggedData, setAllLogedData] = useState(null);
  const [expandContTeam, setExpandContTeam] = useState(null);
  const [expandDevTeam, setExpandDevTeam] = useState(null);
  const [exapndLocTeam, setExpandLocTeam] = useState(null);
  const [expandQcTeam, setExpandQcTeam] = useState(null);
  const [expandFree, setExpandFree] = useState(null);
  const [showAllEmployees, setShowAllEployyes] = useState(false);
  const [showIndet, setShowIndet] = useState(false);
  const [showDevt, setShowDevt] = useState(false);
  const [showLoct, setShowLoct] = useState(false);
  const [showQct, setShowQct] = useState(false);
  const [showFree, setShowFree] = useState(false);
  const [hideWorkPlanBtns, setHideWorkPlanBtns] = useState(false);
  const [showCompressedView, setShowCompressedView] = useState(false);
  const weeks = Array.from({ length: 52 }, (_, index) => index + 1);
  const [allEmployees, setAllEmployees] = useState(null);
  const [indet, setIndet] = useState(null);
  const [devt, setDevt] = useState(null);
  const [loct, setLoct] = useState(null);
  const [qct, setQct] = useState(null);
  const [free, setFree] = useState(null);
  const currentYear = new Date().getFullYear();

  const handleFetchData = async (tableNr) => {
    setLoading(true);
    const response = await fetch('https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts/?esp=empty', {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const err = new Error(response.message || 'Failed to fetch posts!');
      throw err;
    }
    const logs = [];

    for (const key in responseData) {
      const log = {
        id: responseData[key]._id,
        user: responseData[key].user,
        client: responseData[key].client,
        esp: responseData[key].esp,
        country: responseData[key].country,
        module_type: responseData[key].module_type,
        module: responseData[key].module,
        stage: responseData[key].stage,
        task: responseData[key].task,
        hours: responseData[key].hours,
        wpl: responseData[key].wpl,
        workingh: responseData[key].workingh,
        comment: responseData[key].comment,
        date: responseData[key].date,
        cw: responseData[key].cw,
      };
      logs.push(log);
    }
    setLoading(false);
    setAllLogedData(logs);
    handleButtonClick(tableNr);
  };

  const resetValues = () => {
    setShowAllEployyes(false);
    setShowIndet(false);
    setShowDevt(false);
    setShowLoct(false);
    setShowQct(false);
    setShowFree(false);
    setHideWorkPlanBtns(true);
    setExpandAllEmployees(null);
    setExpandContTeam(null);
    setExpandDevTeam(null);
    setExpandLocTeam(null);
    setExpandQcTeam(null);
    setExpandFree(null);
    setShowCompressedView(false);
  };

  const handleButtonClick = (tableNr) => {
    resetValues();
    switch (tableNr) {
      case 1:
        setShowAllEployyes(true);
        break;
      case 2:
        setShowIndet(true);
        break;
      case 3:
        setShowDevt(true);
        break;
      case 4:
        setShowLoct(true);
        break;
      case 5:
        setShowQct(true);
        break;
      case 6:
        setShowFree(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (allLoggedData !== null) {
      let assignedEntries = props.eldData[0].users.map((user) => {
        const userEntries = allLoggedData.filter(
          (entry) => entry.user === user.name && entry.cw === props.selectedWeek
        );
        return { ...user, entry: userEntries ? userEntries : '' };
      });
      assignedEntries.sort((a, b) => {
        const teamOrder = ['devt', 'loct', 'indet', 'qct'];
        return teamOrder.indexOf(a.team) - teamOrder.indexOf(b.team);
      });
      setAllEmployees(assignedEntries);
      setIndet(assignedEntries.filter((user) => user.team === 'indet'));
      setDevt(assignedEntries.filter((user) => user.team === 'devt'));
      setLoct(assignedEntries.filter((user) => user.team === 'loct'));
      setQct(assignedEntries.filter((user) => user.team === 'qct'));
      setFree(assignedEntries.filter((user) => user.team === 'free'));
    }
  }, [allLoggedData, props.selectedWeek]);

  const handleDateConvertion = (dt) => {
    const parts = dt.split('-');
    const yyy = parseInt(parts[0], 10);
    const mm = parseInt(parts[1], 10) - 1;
    const dd = parseInt(parts[2], 10);
    const date = new Date(Date.UTC(yyy, mm, dd));
    date.setUTCHours(0, 0, 0, 0);
    return date;
  };

  const rowExpansionTemplate = (rowData) => {
    const filteredEntries = rowData.entry.filter((entry) => {
      const date = handleDateConvertion(entry.date);
      return date.getFullYear() === currentYear;
    });
    const groupData = _.groupBy(filteredEntries, (entry) => {
      const date = handleDateConvertion(entry.date);
      return date.toISOString().split('T')[0];
    });
    const transformedData = Object.keys(groupData).map((date) => ({
      date,
      workingh: groupData[date].reduce((_, entry) => entry.workingh, 0),
      hours: groupData[date].reduce((total, entry) => total + entry.hours, 0),
      wpl: groupData[date].reduce((_, entry) => entry.wpl, 0),
      entries: groupData[date],
    }));
    const sorted = [...transformedData].sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
      <div className="expanded-table-wrapper">
        <table className="admin-user-work-plan-table">
          <thead>
            <tr>
              <th>{dictionary.admin_panel_plan_detail_table_th1}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th2}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th3}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th4}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th5}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th6}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th7}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th8}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th9}</th>
              <th>{dictionary.admin_panel_plan_detail_table_th10}</th>
            </tr>
          </thead>
          <tbody>
            {sorted.map((entry, idx) => {
              const user = allEmployees.find((employee) => employee.name === entry.entries[0].user);
              const vacation = entry.entries[0].module === 'Urlop';
              const absence = entry.entries[0].module === 'Nieobecność';
              const dayName = handleDateConvertion(entry.date).toLocaleString('en-US', { weekday: 'long' });
              return (
                <tr key={idx}>
                  <th>{dayName}</th>
                  <td role="cell">{entry.workingh === '' ? '-' : entry.workingh}</td>
                  {user && user.person === 'normal' ? (
                    <td role="cell" className={entry.hours >= 8 ? 'green-row' : 'red-row'}>
                      {entry.hours}
                    </td>
                  ) : (
                    <td role="cell" className={entry.hours >= 7 ? 'green-row' : 'red-row'}>
                      {entry.hours}
                    </td>
                  )}
                  <>
                    {!vacation ? (
                      <>
                        {!absence ? (
                          <td role="cell" className={entry.wpl === 'Biuro' ? 'cir_8' : 'cir_9'}>
                            {entry.wpl}
                          </td>
                        ) : (
                          <td role="cell" className="cir_3">
                            {entry.entries[0].module}
                          </td>
                        )}
                      </>
                    ) : (
                      <td role="cell" className="cir_0">
                        {entry.entries[0].module}
                      </td>
                    )}
                  </>

                  <td role="cell">
                    <ul>
                      {entry.entries.map((etr, index) => {
                        return <li key={index}>{etr.client}</li>;
                      })}
                    </ul>
                  </td>
                  <td role="cell">
                    <ul>
                      {entry.entries.map((etr, index) => {
                        return <li key={index}>{etr.country === '' ? '' : etr.country}</li>;
                      })}
                    </ul>
                  </td>
                  <td role="cell">
                    <ul>
                      {entry.entries.map((etr, index) => {
                        return (
                          <li key={index} className={etr.module === 'Wolne moce' ? 'cir_6' : ''}>
                            {etr.module}
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td role="cell">
                    <ul>
                      {entry.entries.map((etr, index) => {
                        return <li key={index}>{etr.stage === '' ? '-' : etr.stage}</li>;
                      })}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {entry.entries.map((etr, index) => {
                        return <li key={index}>{etr.hours}</li>;
                      })}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {entry.entries.map((etr, index) => {
                        return (
                          <li key={index} className="comment-cell" data-pr-tooltip={etr.comment}>
                            {etr.comment && etr.comment.length > 15
                              ? `${etr.comment.substring(0, 15)}...`
                              : etr.comment}
                          </li>
                        );
                      })}
                    </ul>
                    <Tooltip target=".comment-cell" position="top" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const weeksDropdownBody = () => {
    return (
      <div className="cw-dropdown-wrapper">
        <span>Select CW:</span>
        <Dropdown
          value={props.selectedWeek}
          options={weeks}
          onChange={(e) => handleWeekChange(e.value)}
          placeholder={`${props.selectedWeek}`}
        />
      </div>
    );
  };

  const handleWeekChange = (value) => {
    props.setSelectedWeek(value);
  };

  const expandAll = (setState, entries) => {
    let _expandedRows = [];

    entries.forEach((p) => {
      _expandedRows.push(p);
    });
    setState(_expandedRows);
  };

  const collapseAll = (setState) => {
    setState(null);
  };

  const header = (setState, entries) => {
    return (
      <div className="header-btn-wrapper">
        <Button icon="pi pi-plus" label="Expand All" onClick={() => expandAll(setState, entries)} />
        <Button icon="pi pi-minus" label="Collapse All" onClick={() => collapseAll(setState, entries)} />
      </div>
    );
  };

  const teams = {
    allEmployees: allEmployees,
    devt: devt,
    qct: qct,
    indet: indet,
    loct: loct,
    free: free,
    showAllEmployees: showAllEmployees,
    showIndet: showIndet,
    showDevt: showDevt,
    showLoct: showLoct,
    showQct: showQct,
    showFree: showFree,
  };

  const teamsToShow = Object.keys(teams)
    .filter((key) => key.startsWith('show') && teams[key] === true)
    .map((key) => {
      const teamKey = key.slice(4);
      const teamName = teamKey.charAt(0).toLowerCase() + teamKey.slice(1);
      return teams[teamName] || teams['allEmployees'];
    });

  return (
    <div className="user-workplan-container">
      {loading && (
        <div className="loading-wrapper">
          <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="2" animationDuration="3s" />
        </div>
      )}
      <div className="user-workplan-detailed animation-fade-in" style={{ height: !hideWorkPlanBtns ? null : '100%' }}>
        {!hideWorkPlanBtns ? (
          <div className="button-grid">
            <h2>{dictionary.admin_panel_plan_header}</h2>
            <div className="button-row">
              <Button onClick={() => handleFetchData(1)}>
                <h3>{dictionary.admin_panel_plan_btn_title_all}</h3>
                <Divider />
                <span>{dictionary.admin_panel_plan_btn_subtitle}</span>
              </Button>
              <Button onClick={() => handleFetchData(2)}>
                <h3>{dictionary.admin_panel_plan_btn_title_cont}</h3>
                <Divider />
                <span>{dictionary.admin_panel_plan_btn_subtitle}</span>
              </Button>
              <Button onClick={() => handleFetchData(3)}>
                <h3>{dictionary.admin_panel_plan_btn_title_dev}</h3>
                <Divider />
                <span>{dictionary.admin_panel_plan_btn_subtitle}</span>
              </Button>
            </div>
            <div className="button-row">
              <Button onClick={() => handleFetchData(4)}>
                <h3>{dictionary.admin_panel_plan_btn_title_loc}</h3>
                <Divider />
                <span>{dictionary.admin_panel_plan_btn_subtitle}</span>
              </Button>
              <Button onClick={() => handleFetchData(5)}>
                <h3>{dictionary.admin_panel_plan_btn_title_qc}</h3>
                <Divider />
                <span>{dictionary.admin_panel_plan_btn_subtitle}</span>
              </Button>
              <Button onClick={() => handleFetchData(6)}>
                <h3>{dictionary.admin_panel_plan_btn_title_free}</h3>
                <Divider />
                <span>{dictionary.admin_panel_plan_btn_subtitle}</span>
              </Button>
            </div>
          </div>
        ) : (
          <div className="user-work-plan-btn-close-wrapper">
            <Button
              onClick={() => {
                setShowCompressedView(!showCompressedView);
              }}
              icon="pi pi-eye"
              label={
                !showCompressedView
                  ? dictionary.admin_panel_plan_btn_compressed_view
                  : dictionary.admin_panel_plan_btn_compressed_view_hide
              }
            />
            <Button
              onClick={() => {
                resetValues();
                setHideWorkPlanBtns(false);
              }}
              icon="pi pi-times"
            />
          </div>
        )}
        {showCompressedView ? (
          <WorkPlanCompressed
            teamsToShow={teamsToShow}
            currentYear={currentYear}
            handleDateConvertion={handleDateConvertion}
          />
        ) : null}
        {showAllEmployees ? (
          <>
            <div className="table-nav">
              <h2>{dictionary.admin_panel_plan_btn_title_all}</h2>
            </div>
            <DeskAvailability />
            <DataTable
              value={allEmployees}
              expandedRows={expandAllEmployees}
              header={header(setExpandAllEmployees, allEmployees)}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => {
                setExpandAllEmployees(e.data);
              }}
            >
              <Column expander style={{ width: '5rem' }} />
              <Column field="name" header="User name" />
              <Column field="weeks" header={weeksDropdownBody} />
            </DataTable>
          </>
        ) : null}
        {showIndet ? (
          <>
            <div className="table-nav">
              <h2>{dictionary.admin_panel_plan_btn_title_cont}</h2>
            </div>

            <DataTable
              value={indet}
              expandedRows={expandContTeam}
              header={header(setExpandContTeam, indet)}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => {
                setExpandContTeam(e.data);
              }}
            >
              <Column expander style={{ width: '5rem' }} />
              <Column field="name" header="User name" />
              <Column field="weeks" header={weeksDropdownBody} />
            </DataTable>
          </>
        ) : null}
        {showDevt ? (
          <>
            <div className="table-nav">
              <h2>{dictionary.admin_panel_plan_btn_title_dev}</h2>
            </div>

            <DataTable
              value={devt}
              expandedRows={expandDevTeam}
              header={header(setExpandDevTeam, devt)}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => {
                setExpandDevTeam(e.data);
              }}
            >
              <Column expander style={{ width: '5rem' }} />
              <Column field="name" header="User name" />
              <Column field="weeks" header={weeksDropdownBody} />
            </DataTable>
          </>
        ) : null}
        {showLoct ? (
          <>
            <div className="table-nav">
              <h2>{dictionary.admin_panel_plan_btn_title_loc}</h2>
            </div>

            <DataTable
              value={loct}
              expandedRows={exapndLocTeam}
              header={header(setExpandLocTeam, loct)}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => {
                setExpandLocTeam(e.data);
              }}
            >
              <Column expander style={{ width: '5rem' }} />
              <Column field="name" header="User name" />
              <Column field="weeks" header={weeksDropdownBody} />
            </DataTable>
          </>
        ) : null}
        {showQct ? (
          <>
            <div className="table-nav">
              <h2>{dictionary.admin_panel_plan_btn_title_qc}</h2>
            </div>

            <DataTable
              value={qct}
              expandedRows={expandQcTeam}
              header={header(setExpandQcTeam, qct)}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => {
                setExpandQcTeam(e.data);
              }}
            >
              <Column expander style={{ width: '5rem' }} />
              <Column field="name" header="User name" />
              <Column field="weeks" header={weeksDropdownBody} />
            </DataTable>
          </>
        ) : null}
        {showFree ? (
          <>
            <div className="table-nav">
              <h2>{dictionary.admin_panel_plan_btn_title_free}</h2>
            </div>

            <DataTable
              value={free}
              expandedRows={expandFree}
              header={header(setExpandFree, free)}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => {
                setExpandFree(e.data);
              }}
            >
              <Column expander style={{ width: '5rem' }} />
              <Column field="name" header="User name" />
              <Column field="weeks" header={weeksDropdownBody} />
            </DataTable>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default UsersWorkPlan;
