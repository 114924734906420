import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { dictionary } from '../../../dictionary';

const WorkPlanCompressed = (props) => {
  const teams = props.teamsToShow[0];
  const { currentYear, handleDateConvertion } = props;

  const renderUser = (user, index) => {
    const filteredEntries = user.entry.filter((entry) => {
      const date = handleDateConvertion(entry.date);
      return date.getFullYear() === currentYear;
    });

    const groupData = _.groupBy(filteredEntries, (entry) => {
      const date = handleDateConvertion(entry.date);
      return date.toISOString().split('T')[0];
    });

    const transformedData = Object.keys(groupData).map((date) => ({
      date,
      workingh: groupData[date].reduce((_, entry) => entry.workingh, 0),
      hours: groupData[date].reduce((total, entry) => total + entry.hours, 0),
      wpl: groupData[date].reduce((_, entry) => entry.wpl, 0),
      entries: groupData[date],
    }));

    const sorted = [...transformedData].sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
      <div key={index} className="work-plan-compressed-user animation-fade-in">
        <h3>{user.name}</h3>
        <DataTable value={sorted}>
          <Column
            field="date"
            header={dictionary.admin_work_plan_compressed_day}
            body={(rowData) => handleDateConvertion(rowData.date).toLocaleString('en-US', { weekday: 'long' })}
          />
          <Column field="workingh" header={dictionary.admin_work_plan_compressed_wh}></Column>
          <Column
            field="wpl"
            header={dictionary.admin_work_plan_compressed_wpl}
            body={(rowData) => {
              const vacation = rowData.entries[0].module === 'Urlop';
              const absence = rowData.entries[0].module === 'Nieobecność';
              if (vacation) {
                return <div className="cir_0">{rowData.entries[0].module}</div>;
              } else if (absence) {
                return <div className="cir_3">{rowData.entries[0].module}</div>;
              } else {
                return <div className={rowData.wpl === 'Biuro' ? 'cir_8' : 'cir_9'}>{rowData.wpl}</div>;
              }
            }}
          ></Column>
          <Column
            field="entries[0].module"
            header={dictionary.admin_work_plan_compressed_task}
            body={(rowData) => (
              <div className={rowData.entries[0].module === 'Wolne moce' ? 'cir_6' : ''}>
                {rowData.entries[0].module}
              </div>
            )}
          ></Column>
        </DataTable>
      </div>
    );
  };

  return <div className="work-plan-compressed">{teams.map(renderUser)}</div>;
};

export default WorkPlanCompressed;
