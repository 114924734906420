import React, { useState } from "react";
import EldComponent from "./EldComponent";
import IntComponent from "./IntComponent";
import CountryComponent from "./CountriesComponent";
import LogisticsComponent from "./LogisticsComponent";
import OtherComponent from "./OtherComponent";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import ObjectID from "bson-objectid";
import { useSelector, useDispatch } from "react-redux";
import { showEldDialog, showIntDialog, showCtyDialog, showLogDialog, showOthDialog } from "../../redux/actions/dialogActions.js";
import { setClient, setModType } from "../../redux/actions/logActions";
import { fetchInitialPosts } from "../../redux/thunk/thunk";
import { dictionary } from "../../dictionary.js";

const UserSelection = (props) => {
  const dispatch = useDispatch();
  const contentProps = props.contentProps;
  const isDialogOpen = useSelector((state) => state.showDialog.show);
  const dialogType = useSelector((state) => state.showDialog.type);
  const dateLog = new Date(useSelector((state) => state.date.value));
  const clientLog = useSelector((state) => state.client.value);
  const commentLog = useSelector((state) => state.comment.value);
  const ctyLog = useSelector((state) => state.cty.value);
  const espLog = useSelector((state) => state.esp.value);
  const hourLog = useSelector((state) => state.hours.value);
  const modTypeLog = useSelector((state) => state.modType.value);
  const moduleLog = useSelector((state) => state.module.value);
  const stageLog = useSelector((state) => state.stage.value);
  const taskLog = useSelector((state) => state.task.value);
  const wplLog = useSelector((state) => state.wpl.value);
  const whLog = useSelector((state) => state.wh.value);
  const formattedDate = `${dateLog.getDate()}/${dateLog.getMonth() + 1}/${dateLog.getFullYear()}`;
  const disabledDays = [0, 6];
  const [showOverlay, setShowOverlay] = useState(true);
  const [loading, setLoading] = useState(false);

  const sortData = (data) => {
    return [...data].sort((a, b) => a.name.localeCompare(b.name));
  };

  const sameDayWplFixWhileAddingPost = (posts, date) => {
    let wpl = null;
    posts.map((post) => {
      if (post.date === date && post.wpl !== "") {
        wpl = post.wpl;
      }else if(post.date === date && post.wpl === ""){
        wpl = post.wpl;
      }
    });
    return wpl;
  };

  const sameDayWorkingHFixWhileAddingPost = (posts, date) => {
    let wh = null;
    posts.map((post) => {
      if (post.date === date && post.workingh !== "") {
        wh = post.workingh;
      }
    });
    return wh;
  };

  const onLogSave = () => {
    setLoading(true);
    const fixedDate = contentProps.fixDateLog(dateLog);
    const fixedWpl = sameDayWplFixWhileAddingPost(contentProps.userPosts, fixedDate).trim();
    const fixedWorkingH = sameDayWorkingHFixWhileAddingPost(contentProps.userPosts, fixedDate).trim();

    const log = {
      id: ObjectID().toHexString(),
      user: contentProps.loggedUser,
      client: clientLog.toUpperCase(),
      esp: espLog === null ? "" : espLog,
      country: ctyLog === null ? "" : ctyLog,
      module_type: modTypeLog === null ? "" : modTypeLog,
      module: moduleLog,
      stage: stageLog === null ? "" : stageLog,
      task: taskLog === null ? "" : taskLog,
      hours: hourLog,
      wpl: wplLog === null ? fixedWpl : wplLog,
      workingh: whLog === null ? fixedWorkingH : whLog,
      comment: commentLog === null ? "" : commentLog,
      date: fixedDate,
      cw: contentProps.getCw(contentProps.fixDateLog(dateLog)),
    };
    fetch("https://eld-timer-backend-service.api.prod.sit.sys.odj.cloud/posts", {
      method: "POST",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(log),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => {
      dispatch(fetchInitialPosts(contentProps.loggedUser));
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    })
    .finally(() => {
      setLoading(false);
      contentProps.setValuesOnInitialState();
    });
  };

  const dialogProps = {
    isDialogOpen: isDialogOpen,
    eldData: contentProps.eldData,
    loggedUser: contentProps.loggedUser,
    onDialogHide: contentProps.onDialogHide,
    setValuesOnInitialState: contentProps.setValuesOnInitialState,
    disabledDays: disabledDays,
    postEditing: contentProps.postEditing,
    onRowEditComplete: contentProps.onRowEditComplete,
    formattedDate: formattedDate,
    formSubmitted: contentProps.formSubmitted,
    setFormSubbmited: contentProps.setFormSubbmited,
    onLogSave: onLogSave,
    sortData: (data) => sortData(data),
    checkIfDayIsPlanned: contentProps.checkIfDayIsPlanned,
    showOverlay: showOverlay,
    setShowOverlay: setShowOverlay,
  };

  return (
    <div className="create-log-wrapper">
      <h2>{dictionary.create_log_hour_report}</h2>
      <Button
        label={dictionary.eld_btn_label}
        icon="pi pi-external-link"
        onClick={() => {
          if (contentProps.checkIfDayIsPlanned(new Date())) {
            dispatch(setClient("eld"));
            dispatch(showEldDialog());
          } else {
            contentProps.showMsg();
          }
        }}
      />
      <Button
        label={dictionary.int_btn_label}
        icon="pi pi-external-link"
        onClick={() => {
          if (contentProps.checkIfDayIsPlanned(new Date())) {
            dispatch(setClient("int"));
            dispatch(showIntDialog());
          } else {
            contentProps.showMsg();
          }
        }}
      />
      <Button
        label={dictionary.countries_btn_label}
        icon="pi pi-external-link"
        onClick={() => {
          if (contentProps.checkIfDayIsPlanned(new Date())) {
            dispatch(setClient("country"));
            dispatch(showCtyDialog());
            dispatch(setModType("FtF"));
          } else {
            contentProps.showMsg();
          }
        }}
      />
      <Button
        label={dictionary.log_btn_label}
        icon="pi pi-external-link"
        onClick={() => {
          if (contentProps.checkIfDayIsPlanned(new Date())) {
            dispatch(setClient("logistics"));
            dispatch(showLogDialog());
          } else {
            contentProps.showMsg();
          }
        }}
      />
      <Button
        label={dictionary.other_btn_label}
        icon="pi pi-external-link"
        onClick={() => {
          if (contentProps.checkIfDayIsPlanned(new Date())) {
            dispatch(setClient("other"));
            dispatch(showOthDialog());
          } else {
            contentProps.showMsg();
          }
        }}
      />
      <BlockUI blocked = {loading}>
        <div className="dialog-wrapper">{dialogType === "eld" && <EldComponent dialogProps={dialogProps} />}</div>
        <div className="dialog-wrapper">{dialogType === "int" && <IntComponent dialogProps={dialogProps} />}</div>
        <div className="dialog-wrapper">{dialogType === "country" && <CountryComponent dialogProps={dialogProps} />}</div>
        <div className="dialog-wrapper">{dialogType === "logistic" && <LogisticsComponent dialogProps={dialogProps} />}</div>
        <div className="dialog-wrapper">{dialogType === "other" && <OtherComponent dialogProps={dialogProps} />}</div>
      </BlockUI>    
    </div>
  );
};

export default UserSelection;
