import React from 'react';
import { dictionary } from '../../dictionary';
import MinorProjectsCountry from './MinorProjectsCountry';

const CountryReportTable = (props) => {
  const { properties, styles } = props;
  const stages = properties.eldData[0].stages_localization;
  const stagesDev = properties.eldData[0].stages_development;
  const stagesWithoutCheck = stages.filter((stage) => !stage.name.includes('check'));
  const hoursSum = properties.logsToDisplay.reduce((total, obj) => {
    return total + obj.hours;
  }, 0);

  const majorProjSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.module === 'Drobne_projekty') {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const eld0Sum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('ELD0')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const learningConceptSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Learning Concept')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const eld1Sum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('ELD1')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const storyboardSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Storyboard')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const eld2Sum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('ELD2')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const visualVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Visual Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const eld3Sum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('ELD3')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const clickableVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Clickable Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const eld4Sum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('ELD4')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const adaptChangesSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Adapt Changes')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const eld5Sum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('ELD5')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const finalVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Final Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const addChanges = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Additional changes')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const qCSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('QC')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const signOff = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Sign_off')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const tableObj = {
    eld_0: eld0Sum,
    eld_1: eld1Sum,
    eld_2: eld2Sum,
    eld_3: eld3Sum,
    eld_4: eld4Sum,
    eld_5: eld5Sum,
    add_changes: addChanges,
    major_proj: majorProjSum,
  };

  const tableObjWithSignOff = {
    eld_0: eld0Sum,
    eld_1: eld1Sum,
    eld_2: eld2Sum,
    eld_3: eld3Sum,
    eld_4: eld4Sum,
    eld_5: eld5Sum,
    add_changes: addChanges,
    sign_off: signOff,
    major_proj: majorProjSum,
  };

  const tableObjDev = {
    learning_concept: learningConceptSum,
    storyBoard: storyboardSum,
    visual_version: visualVersionSum,
    clickable_version: clickableVersionSum,
    adapt_changes: adaptChangesSum,
    final_version: finalVersionSum,
    qc: qCSum,
    sign_off: signOff,
  };

  return (
    <>
      <thead>
        <tr>
          <th></th>
          {properties.uniqueModule.map((module, index) => {
            if (module.includes('Drobne_projekty')) {
              module = module.replace('Drobne_projekty', 'Minor_projects');
            }
            return (
              <th key={index} className="header" style={styles.header}>
                {module}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{dictionary.export_table_th_loc}</td>
        </tr>
        {stagesWithoutCheck.map((stage, index) => (
          <tr key={index} style={index % 2 === 0 ? styles.green : styles.blue}>
            <th className="thdefault" style={styles.thdefault}>
              {stage.name}
            </th>
            {properties.uniqueModule.map((module, index) => {
              const totalHours = properties.logsToDisplay.reduce((total, item) => {
                item.stage = item.stage.replace(' - check', '');
                if (item.module === module && item.stage === stage.name) {
                  return total + item.hours;
                }
                return total;
              }, 0);
              return totalHours ? (
                <td key={index} className="tddefault" style={styles.tddefault}>
                  {totalHours.toString().replace('.', ',')}
                </td>
              ) : (
                <td key={index} className="tddefault" style={styles.tddefault}>
                  {''}
                </td>
              );
            })}
          </tr>
        ))}
        <tr>
          <td>{dictionary.export_table_th_dev}</td>
        </tr>
        {stagesDev.map((stage, index) => (
          <tr key={index} style={index % 2 === 0 ? styles.green : styles.blue}>
            <th className="thdefault" style={styles.thdefault}>
              {stage.name}
            </th>
            {properties.uniqueModule.map((module, index) => {
              const item = properties.logsToDisplay.find((item) => item.module === module && item.stage === stage.name);
              return item ? (
                <td key={index} className="tddefault" style={styles.tddefault}>
                  {item.hours.toString().replace('.', ',')}
                </td>
              ) : (
                <td key={index} className="tddefault" style={styles.tddefault}>
                  {''}
                </td>
              );
            })}
          </tr>
        ))}
        <tr className="orange" style={styles.orange}>
          <td className="orange" style={styles.orange}>
            Localization FtF - {new Date().getFullYear()} fiscal only
          </td>
          {properties.sumValues.map((sum, index) => (
            <td key={index} className="orange" style={styles.orange}>
              {sum.toString().replace('.', ',')}
            </td>
          ))}
        </tr>
        <tr>
          <th>{dictionary.export_table_th_loc}</th>
          <th>{dictionary.export_table_th_hours}</th>
          {/* <th>
            {dictionary.export_table_th_costs +
              "(" +
              properties.costRate +
              dictionary.currency +
              ")"}
          </th> */}
        </tr>
        {Object.keys(tableObj).map((key, index) => (
          <tr key={index}>
            <th className="gray" style={styles.gray}>
              {key}
            </th>
            <td key={key} className="tddefault" style={styles.tddefault}>
              {tableObj[key].toString().replace('.', ',')}
            </td>
            {/* <td className="tddefault" style={styles.tddefault}>
              {(tableObj[key] * properties.costRate)
                .toString()
                .replace(".", ",") + dictionary.currency}
            </td> */}
          </tr>
        ))}
        <tr>
          <th>{dictionary.export_table_th_dev}</th>
        </tr>
        {Object.keys(tableObjDev).map((key, index) => (
          <tr key={index}>
            <th className="gray" style={styles.gray}>
              {key}
            </th>
            <td key={key} className="tddefault" style={styles.tddefault}>
              {tableObjDev[key]}
            </td>
            {/* <td className="tddefault" style={styles.tddefault}>
              {(tableObjDev[key] * properties.costRate)
                .toString()
                .replace(".", ",") + dictionary.currency}
            </td> */}
          </tr>
        ))}
        <tr>
          <th className="gray" style={styles.gray}>
            {dictionary.export_table_summary}
          </th>
          <td className="orange" style={styles.orange}>
            {hoursSum.toString().replace('.', ',')}
          </td>
          {/* <td className="orange" style={styles.orange}>
            {(hoursSum * properties.costRate).toString().replace(".", ",") +
              dictionary.currency}
          </td> */}
          <td>{<MinorProjectsCountry properties={properties} />}</td>
        </tr>
      </tbody>
    </>
  );
};

export default CountryReportTable;
