import React from 'react';
import CountryReportTable from './CountryReportTable';
import INTReportTable from './INTReportTable.js';
import MinorProjects from './MinorProjects';
import { dictionary } from '../../dictionary.js';

const DefaultReportTable = (props) => {
  const { properties, styles, client } = props;
  const stagesDev = properties.eldData[0].stages_development;
  const startDate =
    properties.dates.length > 1
      ? properties.monthNames[properties.dates[0].getMonth()] + properties.dates[0].getFullYear()
      : null;
  const endDate =
    properties.dates.length > 1
      ? properties.monthNames[properties.dates[1].getMonth()] + properties.dates[1].getFullYear()
      : null;
  const dateRange = !properties.dates.length
    ? properties.monthNames[properties.dates.getMonth()] + properties.dates.getFullYear()
    : null;

  const learningConceptSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Learning Concept')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const storyboardSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Storyboard')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const visualVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Visual Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const clickableVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Clickable Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const adaptChangesSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Adapt Changes')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const finalVersionSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Final Version')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const qCSum = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('QC')) {
      return total + obj.hours;
    }
    return total;
  }, 0);

  const signOff = properties.logsToDisplay.reduce((total, obj) => {
    if (obj.stage.includes('Sign_off')) {
      return total + obj.hours;
    }
    return total;
  }, 0);
  const defaultStyles = {
    header: {
      background: '#FFF2CC',
      border: 'solid black',
      borderWidth: 'thin',
      width: 'auto',
    },
    gray: {
      background: '#D9D9D9',
      border: 'solid black',
      borderWidth: 'thin',
    },
    empty: {
      border: 'solid black',
      borderWidth: 'thin',
      textAlign: 'right',
    },
    green: {
      background: '#C6E0B4',
      border: 'solid black',
      borderWidth: 'thin',
      fontWeight: 'bold',
    },
    greenSum: {
      background: '#C6E0B4',
      border: 'solid black',
      borderWidth: 'thin',
      textAlign: 'right',
      fontWeight: 'bold',
    },
  };

  const tableObjDev = {
    learning_concept: learningConceptSum,
    storyBoard: storyboardSum,
    visual_version: visualVersionSum,
    clickable_version: clickableVersionSum,
    adapt_changes: adaptChangesSum,
    final_version: finalVersionSum,
    qc: qCSum,
    sign_off: signOff,
  };

  return (
    <>
      <table ref={properties.tableRef}>
        {client === 'COUNTRY' ? (
          <>
            <CountryReportTable properties={properties} styles={styles} />
          </>
        ) : null}
        {client === 'ELD' ? (
          <>
            <thead>
              <tr>
                <th className="header" style={defaultStyles.header}>
                  {properties.dates.length > 1
                    ? dictionary.export_dialog_from + startDate + ' ' + dictionary.export_dialog_to + endDate
                    : dateRange}
                </th>
                <th className="header" style={defaultStyles.header}>
                  {dictionary.export_table_th_hours}
                </th>
                {/* <th className="header" style={defaultStyles.header}>
                  {dictionary.export_table_th_costs +
                    "(" +
                    properties.costRate +
                    dictionary.currency +
                    ")"}
                </th> */}
              </tr>
            </thead>
            <tbody>
              {properties.logsToDisplay !== null
                ? properties.logsToDisplay.map((item, index) => {
                    if (item.module.includes('Drobne_projekty')) {
                      item.module = item.module.replace('Drobne_projekty', 'Minor_projects');
                    }
                    return (
                      <tr key={index}>
                        <td className="gray" style={defaultStyles.gray}>
                          {item.module}
                        </td>
                        <td className="empty" style={defaultStyles.empty}>
                          {item.hours.toString().replace('.', ',')}
                        </td>
                        {/* <td className="empty" style={defaultStyles.empty}>
                          {(item.hours * properties.costRate)
                            .toString()
                            .replace(".", ",") + dictionary.currency}
                        </td> */}
                      </tr>
                    );
                  })
                : null}

              <tr>
                <td className="green" style={defaultStyles.green}>
                  {dictionary.export_table_summary}
                </td>
                <td className="green" style={defaultStyles.greenSum}>
                  {properties.logsToDisplay
                    .reduce((total, obj) => obj.hours + total, 0)
                    .toString()
                    .replace('.', ',')}
                </td>
                {/* <td className="green" style={defaultStyles.greenSum}>
                  {(
                    properties.logsToDisplay.reduce(
                      (total, obj) => obj.hours + total,
                      0
                    ) * properties.costRate
                  )
                    .toString()
                    .replace(".", ",") + dictionary.currency}
                </td> */}
              </tr>
              {/* <tr>
                <td>{properties.client !== 'ELD' && <MinorProjects properties={properties} />}</td>
              </tr> */}
            </tbody>
          </>
        ) : null}
        {client === 'INT' || client === 'LOGISTICS' ? (
          // <>
          //   <tr>
          //     <th>{dictionary.export_table_th_dev}</th>
          //   </tr>
          //   {Object.keys(tableObjDev).map((key, index) => (
          //     <tr key={index}>
          //       <th className="gray" style={styles.gray}>
          //         {key}
          //       </th>
          //       <td key={key} className="tddefault" style={styles.tddefault}>
          //         {tableObjDev[key]}
          //       </td>
          //     </tr>
          //   ))}
          // </>
          <INTReportTable properties={properties} styles={styles} />
        ) : null}
      </table>
    </>
  );
};

export default DefaultReportTable;
