const initWidgetInfo = [
  {
    title: "Release TK2",
    vnr: "2.6.4",
    info: "Naprawiono błędne wyświetlanie wpisów w skompresowanym widoku teamu w panelu admina. Etapy produkcji w raportach dla INT zostały dodane.",
  },
  {
    title: "Release TK1",
    vnr: "2.6.3",
    info: "Usunięto wybór Medycyny pracy z planowania miejsca pracy. Usprawniono system zapisywania danych w oknach dialogowych dot. wpisów i planowania dni w workplanerze.",
  },
];

const widgetReducer = (state = initWidgetInfo, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default widgetReducer;